import { Component, OnInit } from '@angular/core';
import { product, Productlist, protype, prosubtype } from '../../services/productlist';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-bikes',
  templateUrl: './bikes.component.html',
  styleUrls: ['./bikes.component.css',]
})
export class BikesComponent implements OnInit {
prolist:product[]=[];
prosubtype:any[]=[];
protype:any[]=[];
mainlist:any[]=[{}];
list:any={Name:""};
TypeName:string="PRODUCT RANGE";
id:any;
  constructor(private route: ActivatedRoute,private router: Router,public ngxSmartModalService: NgxSmartModalService) 
  {
    this.protype= protype;
    this.TypeName="PRODUCT RANGE";
    this.route.queryParams.subscribe(params => {
      this.prosubtype = prosubtype.filter(t=>t.cattype==params['id']);
    var liName=  this.protype.filter(x=>x.id==params['id']);
    liName.forEach(element => {
     this.TypeName= element.Name;
    });
    
       console.log(this.prosubtype);
       this.mainlist=[];
      this.prosubtype.forEach(element => {
        let obj= Productlist.filter(t=>t.Type === element.id);
        this.mainlist.push({Name:element.Name,list:obj})
      });
     // this.prolist = Productlist.filter(t=>t.Type == params['id']);
      console.log(params);
       this.id = params['id'];
       this.TypeName=params['id']==null?"PRODUCT RANGE":this.TypeName;
    })
   
   }
   goproduct(id:string){
    this.router.navigate(['/bikes'], { queryParams: { id: id } });
   }
   bcde(list:any){
    this.list=list;
   }

  ngOnInit() {
  }

}
