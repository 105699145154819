
export interface product{
 Name:string,
 Size:string,
 Color:any,
 Type:number,
 img:string,

}
export const protype:any[]=[
    {id:1,Name:"Roadster Series",img:"series1.jpg"},
    {id:2,Name:"Boys & Men Series",img:"series2.jpg"},
    {id:3,Name:"Girls & Women Series",img:"series3.jpg"},
    {id:4,Name:"Kids Series",img:"series4.jpg"},
    {id:5,Name:"Cityride Series",img:"series5.jpg"},
    {id:6,Name:"New Arrivals",img:"series6.jpg"},
]
export const prosubtype:any[]=[
    {id:1,Name:"Goldstar ",cattype:1},
    {id:2,Name:"Silverstar ",cattype:1},
    {id:3,Name:"",cattype:2},
    {id:4,Name:"",cattype:3},
    {id:5,Name:"",cattype:4},
    {id:6,Name:"",cattype:5},
    {id:7,Name:"",cattype:6},
]
export const Productlist:product[]=[
    {
    Name:"Captain India Jr. IBC",
    Color:[
        {code:"jumni"},
        {code:"black"},
        {code:"blue"},
    ],
    Size:"Wheel Size – 26 x 1.50",
    Type:1,
    img:"roadstar1.jpg"
    },
    {
        Name:"Captain India Jr. IBC",
        Color:[
        {code:"redblack"},
        {code:"greenblack"},
        {code:"blackwhite"},
        {code:"jumni"},
        ],
        Size:"Wheel Size – 26 x 1",
        Type:1,
        img:"roadstar.jpg"
   },
   {
    Name:"Captain India Jr",
    Color:[
        {code:"jumni"},
        {code:"black"},
        {code:"blue"},
    ],
    Size:"Wheel Size – 26 x 1.50",
    Type:1,
    img:"roadstar3.jpg"
    },
    {
        Name:"D-Force.",
        Color:[
            {code:"jumni"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:1,
        img:"roadstar4.jpg"
    },
    {
        Name:"Goldclass 20",
        Color:[
            {code:"jumni"},
            {code:"black"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:1,
        img:"roadstar5.jpg"
    },
    {
        Name:"Goldclass 22 D-Bar",
        Color:[
            {code:"jumni"},
            {code:"pink"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:1,
        img:"roadstar6.jpg"
    },
    {
        Name:"Goldclass 22 HD",
        Color:[
            {code:"jumni"},
            {code:"pink"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:1,
        img:"roadstar7.jpg"
    },
    {
        Name:"Goldclass 22",
        Color:[
            {code:"jumni"},
            {code:"pink"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:1,
        img:"roadstar8.jpg"
    },
    {
        Name:"Prime 22",
        Color:[
            {code:"jumni"},
            {code:"pink"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:1,
        img:"roadstar9.jpg"
    },
    {
        Name:"Rose 20",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:1,
        img:"roadstar10.jpg"
    },
    {
        Name:"Rose Ultra",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:1,
        img:"roadstar11.jpg"
    },
    {
        Name:"Suzy 20 CB",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:1,
        img:"roadstar12.jpg"
    },
    {
        Name:"Suzy 22",
        Color:[
            {code:"jumni"},
            {code:"pink"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:1,
        img:"roadstar13.jpg"
    },
    {
        Name:"Suzy Ultra",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:1,
        img:"roadstar14.jpg"
    },
    {
        Name:"VIVA",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:1,
        img:"roadstar15.jpg"
    },
    {
        Name:"D-Fighter",
        Color:[
            {code:"jumni"},
            {code:"pink"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:2,
        img:"silverstar1.jpg"
    },
    {
        Name:"Silverstar 20 CB",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:2,
        img:"silverstar2.jpg"
    },
    {
        Name:"Silverstar 20 IBC",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:2,
        img:"silverstar3.jpg"
    },
    {
        Name:"Silverstar 20 P-Bar",
        Color:[
            {code:"black"},
            {code:"green"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:2,
        img:"silverstar4.jpg"
    },
    {
        Name:"Silverstar 20",
        Color:[
            {code:"jumni"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:2,
        img:"silverstar5.jpg"
    },
    {
        Name:"Silverstar 22 HD",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"blackwhite"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 26 x 1",
        Type:2,
        img:"silverstar6.jpg"
    },
    {
        Name:"Silverstar 22",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"blackwhite"},
            {code:"jumni"},
        ],
        Size:"Wheel Size – 26 x 1",
        Type:2,
        img:"silverstar7.jpg"
    },
    {
        Name:"7.0 DD DS",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
            {code:"bluewhite"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:3,
        img:"boysgirls1.jpg"
    },
    {
        Name:"7.0 DS",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:3,
        img:"boysgirls2.jpg"
    },
    {
        Name:"7.0",
        Color:[
            {code:"redblack"},
            {code:"blueblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:3,
        img:"boysgirls3.jpg"
    },
    {
        Name:"A-RIDE",
        Color:[
            {code:"redblack"},
            {code:"blueblack"},
            {code:"greenblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:3,
        img:"boysgirls4.jpg"
    },
    {
        Name:"ATOMIC 24T DS",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:3,
        img:"boysgirls5.jpg"
    },
    {
        Name:"ATOMIC 24T FD",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"bluewhite"},
            {code:"orangblack"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:3,
        img:"boysgirls6.jpg"
    },
    {
        Name:"ATOMIC 24T",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"bluewhite"},
            {code:"orangblack"},
            {code:"blackwhite"},
            {code:"black"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:3,
        img:"boysgirls7.jpg"
    },
    {
        Name:"D-5000 24T 18-SPEED",
        Color:[
            {code:"red"},
            {code:"green"},
            {code:"orange"},
            {code:"black"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls8.jpg"
    },
    {
        Name:"D-5000 24T",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls9.jpg"
    },
    {
        Name:"D-5000 26 DS",
        Color:[
            {code:"red"},
            {code:"green"},
            {code:"blue"},
            {code:"black"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls10.jpg"
    },
    {
        Name:"D-5000 26 TX",
        Color:[
            {code:"jumni"},
            {code:"blue"},
            {code:"black"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls11.jpg"
    },
    {
        Name:"D-5000 26T 18-SPEED",
        Color:[
            {code:"gray"},
            {code:"blue"},
            {code:"black"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls12.jpg"
    },
    {
        Name:"D-5000 26T",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls13.jpg"
    },
    {
        Name:"D-5000 26TX IBC DS",
        Color:[
            {code:"red"},
            {code:"black"},
            {code:"blue"},
            {code:"green"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls14.jpg"
    },
    {
        Name:"EXCITE 24T",
        Color:[
            {code:"jumni"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls15.jpg"
    },
    {
        Name:"EXCITE 26T",
        Color:[
            {code:"gray"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls16.jpg"
    },
    {
        Name:"FANTASTIC DSK",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls17.jpg"
    },
    {
        Name:"FANTASTIC SS",
        Color:[
            {code:"red"},
            {code:"black"},
            {code:"blue"},
            {code:"green"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls18.jpg"
    },
    {
        Name:"FANTASTIC",
        Color:[
            {code:"jumni"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls19.jpg"
    },
    {
        Name:"KONTRA 26T",
        Color:[
            {code:"gray"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls20.jpg"
    },
    {
        Name:"MARVEL SLR",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls21.jpg"
    },
    {
        Name:"MASCOTT 26T IBC",
        Color:[
            {code:"red"},
            {code:"black"},
            {code:"blue"},
            {code:"green"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls22.jpg"
    },
    {
        Name:"MAXXIM 18-SPEED",
        Color:[
            {code:"jumni"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls23.jpg"
    },
    {
        Name:"MAXXIM TX IBC",
        Color:[
            {code:"gray"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls24.jpg"
    },
    {
        Name:"MAXXIM TX",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls25.jpg"
    },
    {
        Name:"RAIDER 26T",
        Color:[
            {code:"red"},
            {code:"black"},
            {code:"blue"},
            {code:"green"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls26.jpg"
    },
    {
        Name:"RAIDER SS",
        Color:[
            {code:"jumni"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls27.jpg"
    },
    {
        Name:"ROADRAPTOR SS",
        Color:[
            {code:"gray"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls28.jpg"
    },
    {
        Name:"ROADRAPTOR",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls29.jpg"
    },
    {
        Name:"TRON 26T",
        Color:[
            {code:"red"},
            {code:"black"},
            {code:"blue"},
            {code:"green"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls30.jpg"
    },
    {
        Name:"WARRIOR 26T",
        Color:[
            {code:"jumni"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls31.jpg"
    },
    {
        Name:"WARRIOR SS",
        Color:[
            {code:"gray"},
            {code:"black"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:3,
        img:"boysgirls32.jpg"
    },
    {
        Name:"Zoomer 26T",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
            {code:"orangblack"},
            {code:"blackwhite"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:3,
        img:"boysgirls33.jpg"
    },
    {
        Name:"AURA SLR",
        Color:[
            {code:"pink"},
            {code:"lp"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:4,
        img:"girlswomen1.jpg"
    },
    {
        Name:"AVA 26T",
        Color:[
            {code:"pink"},
            {code:"jumni"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:4,
        img:"girlswomen2.jpg"
    },
    {
        Name:"DESTINY",
        Color:[
            {code:"pink"},
            {code:"lp"},
            {code:"lb"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:4,
        img:"girlswomen3.jpg"
    },
    {
        Name:"LADYSTAR 24T",
        Color:[
            {code:"pink"},
            {code:"lp"},
            {code:"lb"},
        ],
        Size:"Wheel Size – 26 x 1.75",
        Type:4,
        img:"girlswomen4.jpg"
    },
    {
        Name:"LADYSTAR MTB",
        Color:[
            {code:"wp"},
            {code:"jumni"},
            {code:"wpur"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:4,
        img:"girlswomen5.jpg"
    },
    {
        Name:"LADYSTAR SLR",
        Color:[
            {code:"pink"},
            {code:"jumni"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 26 x 1 3/8",
        Type:4,
        img:"girlswomen6.jpg"
    },
    {
        Name:"VIVA",
        Color:[
            {code:"pink"},
            {code:"jumni"},
            {code:"purple"},
        ],
        Size:"Wheel Size – 24 x 1.75",
        Type:4,
        img:"girlswomen7.jpg"
    },
    {
        Name:"Buck (Dual Shox)",
        Color:[
            {code:"redblack"},
            {code:"redblack"},
        ],
        Size:"Wheel Size – 20 x 2.125",
        Type:5,
        img:"kids-1.jpg"
    },
    {
        Name:"Buck",
        Color:[
            {code:"redblack"},
            {code:"redblack"},
        ],
        Size:"Wheel Size – 20 x 2.125",
        Type:5,
        img:"kids-2.jpg"
    },
    {
        Name:"Jumper",
        Color:[
            {code:"red"},
            {code:"green"},
            {code:"gray"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 20 x 2.125",
        Type:5,
        img:"kids-3.jpg"
    },
    {
        Name:"Jumper Ultra",
        Color:[
            {code:"red"},
            {code:"green"},
            {code:"gray"},
            {code:"yellow"},
        ],
        Size:"Wheel Size – 20 x 2.125",
        Type:5,
        img:"kids-4.jpg"
    },
    {
        Name:"Groovy",
        Color:[
            {code:"rw"},
            {code:"gp"},
            {code:"pp"},
            {code:"wp"},
        ],
        Size:"Wheel Size – 20 x 1.75",
        Type:5,
        img:"kids-5.jpg"
    },
    {
        Name:"Kidrox",
        Color:[
            {code:"red"},
            {code:"green"},
            {code:"pink"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 20 x 1.75",
        Type:5,
        img:"kids-6.jpg"
    },
    {
        Name:"Kidrox Ultra",
        Color:[
            {code:"red"},
            {code:"green"},
            {code:"pink"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 20 x 1.75",
        Type:5,
        img:"kids-7.jpg"
    },
    {
        Name:"Masti 20",
        Color:[
            {code:"red"},
            {code:"pink"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 20 x 1.75",
        Type:5,
        img:"kids-8.jpg"
    },
    {
        Name:"Masti 16T",
        Color:[
            {code:"red"},
            {code:"pink"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 16 x 1.75",
        Type:5,
        img:"kids-9.jpg"
    },
    {
        Name:"Trix",
        Color:[
            {code:"rw"},
            {code:"gp"},
            {code:"pb"},
        ],
        Size:"Wheel Size – 16 x 1.75",
        Type:5,
        img:"kids-10.jpg"
    },
    {
        Name:"Top Kat",
        Color:[
            {code:"red"},
            {code:"pink"},
        ],
        Size:"Wheel Size – 14 x 1.75",
        Type:5,
        img:"kids-11.jpg"
    },
    {
        Name:"Top Kat",
        Color:[
            {code:"red"},
            {code:"pink"},
        ],
        Size:"Wheel Size – 14 x 1.75",
        Type:5,
        img:"kids-12.jpg"
    },
    {
        Name:"Masti 16T",
        Color:[
            {code:"red"},
            {code:"pink"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 16 x 1.75",
        Type:5,
        img:"kids-13.jpg"
    },
    {
        Name:"Trix",
        Color:[
            {code:"rw"},
            {code:"gp"},
            {code:"pb"},
        ],
        Size:"Wheel Size – 16 x 1.75",
        Type:5,
        img:"kids-14.jpg"
    },
    {
        Name:"Top Kat",
        Color:[
            {code:"red"},
            {code:"pink"},
        ],
        Size:"Wheel Size – 14 x 1.75",
        Type:5,
        img:"kids-15.jpg"
    },
    {
        Name:"Top Kat",
        Color:[
            {code:"red"},
            {code:"pink"},
        ],
        Size:"Wheel Size – 14 x 1.75",
        Type:5,
        img:"kids-16.jpg"
    },
    {
        Name:"Masti 16T",
        Color:[
            {code:"red"},
            {code:"pink"},
            {code:"blue"},
        ],
        Size:"Wheel Size – 16 x 1.75",
        Type:5,
        img:"kids-17.jpg"
    },
    {
        Name:"Trix",
        Color:[
            {code:"rw"},
            {code:"gp"},
            {code:"pb"},
        ],
        Size:"Wheel Size – 16 x 1.75",
        Type:5,
        img:"kids-18.jpg"
    },
    {
        Name:"Top Kat",
        Color:[
            {code:"red"},
            {code:"pink"},
        ],
        Size:"Wheel Size – 14 x 1.75",
        Type:5,
        img:"kids-19.jpg"
    },
    {
        Name:"Top Kat",
        Color:[
            {code:"red"},
            {code:"pink"},
        ],
        Size:"Wheel Size – 14 x 1.75",
        Type:5,
        img:"kids-20.jpg"
    },
    {
        Name:"Blackfire 26T D/S",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
        ],
        Size:"Wheel Size –26x1.75",
        Type:6,
        img:"tx-series-1.jpg"
    },
    {
        Name:"Blackfire 26T S/S",
        Color:[
            {code:"redblack"},
            {code:"greenblack"},
        ],
        Size:"Wheel Size –26x1.75",
        Type:6,
        img:"tx-series-2.jpg"
    },
    {
        Name:"City Ride 6-Speed",
        Color:[
            {code:"red"},
            {code:"black"},
            {code:"gray"},
        ],
        Size:"Wheel Size –26x1.75",
        Type:6,
        img:"tx-series-3.jpg"
    },
    {
        Name:"City Ride Lady 6-Speed",
        Color:[
            {code:"pink"},
            {code:"black"},
            {code:"gray"},
        ],
        Size:"Wheel Size –26x1.75",
        Type:6,
        img:"tx-series-4.jpg"
    },
    {
        Name:"City Ride",
        Color:[
            {code:"red"},
            {code:"black"},
            {code:"gray"},
        ],
        Size:"Wheel Size –26x1.75",
        Type:6,
        img:"tx-series-5.jpg"
    },
    {
        Name:"DNS Air 1.0 Multispeed",
        Color:[
            {code:"black"},
            {code:"orange"},
        ],
        Size:"Wheel Size – 26 x 2.10",
        Type:6,
        img:"tx-series-6.jpg"
    },
    {
        Name:"Mascott 24T",
        Color:[
            {code:"black"},
            {code:"red"},
            {code:"orange"},
        ],
        Size:"Wheel Size –24x2.125",
        Type:6,
        img:"tx-series-7.jpg"
    },
    {
        Name:"Mascott 24T F/S",
        Color:[
            {code:"black"},
            {code:"red"},
            {code:"orange"},
        ],
        Size:"Wheel Size –24x2.125",
        Type:6,
        img:"tx-series-8.jpg"
    },
    {
        Name:"Mascott26- 6 Speed",
        Color:[
            {code:"black"},
            {code:"orange"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:6,
        img:"tx-series-9.jpg"
    },
    {
        Name:"Mascott 26T",
        Color:[
            {code:"black"},
            {code:"red"},
            {code:"orange"},
        ],
        Size:"Wheel Size – 26x2.10",
        Type:6,
        img:"tx-series-10.jpg"
    },
    {
        Name:"MASCOTT 26 SS FS",
        Color:[
            {code:"black"},
            {code:"jumni"},
            {code:"green"},
        ],
        Size:"Wheel Size – 28 x 1.50",
        Type:6,
        img:"tx-series-11.jpg"
    },
    {
        Name:"Dns Air 1.0 Single Speed",
        Color:[
            {code:"black"},
            {code:"orange"},
        ],
        Size:"Wheel Size – 26x2.10",
        Type:6,
        img:"tx-series-12.jpg"
    },
    {
        Name:"Beast 24T",
        Color:[
            {code:"black"},
            {code:"orange"},
        ],
        Size:"Wheel Size –24x2.40",
        Type:6,
        img:"tx-series-13.jpg"
    },
    {
        Name:"Bright 20T",
        Color:[
            {code:"greenblack"},
            {code:"yblack"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:7,
        img:"newarrival-1.jpg"
    },
    {
        Name:"City Ride Girls 6-Speed",
        Color:[
            {code:"pink"},
            {code:"lp"},
            {code:"lb"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:7,
        img:"newarrival-2.jpg"
    },
    {
        Name:"DNS Air 1.0 Multispeed",
        Color:[
            {code:"pink"},
            {code:"lp"},
            {code:"lb"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:7,
        img:"newarrival-3.jpg"
    },
    {
        Name:"Glow 14T",
        Color:[
            {code:"pink"},
            {code:"lp"},
            {code:"lb"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:7,
        img:"newarrival-4.jpg"
    },
    {
        Name:"Mascott 24T FS",
        Color:[
            {code:"greenblack"},
            {code:"yblack"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:7,
        img:"newarrival-5.jpg"
    },
    {
        Name:"Silverstar 20 IBC.",
        Color:[
            {code:"pink"},
            {code:"lp"},
            {code:"lb"},
        ],
        Size:"Wheel Size – 26 x 1.50",
        Type:7,
        img:"newarrival-6.jpg"
    },
]