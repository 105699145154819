import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule, ModalModule } from 'ngx-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NavbarComponent } from './pages/navbar/navbar.component';
import { HomeComponent } from './pages/home/home.component';
import { CarouselComponent } from './pages/carousel/carousel.component';
import { AboutComponent } from './pages/about/about.component';
import { TabsModule } from 'ngx-bootstrap';
import { DnsnewsComponent } from './pages/dnsnews/dnsnews.component';
import { CertificationComponent } from './pages/certification/certification.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { BikesComponent } from './pages/bikes/bikes.component';
import { IndexComponent } from './index/index.component';
import { FooterComponent } from './pages/footer/footer.component';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material';
import { MobNavComponent } from './pages/mob-nav/mob-nav.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    CarouselComponent,
    AboutComponent,
    DnsnewsComponent,
    CertificationComponent,
    ContactusComponent,
    BikesComponent,
    IndexComponent,
    FooterComponent,
    MobNavComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule,
    CollapseModule.forRoot(),
    CarouselModule,
    TabsModule.forRoot(),
    NgxSmartModalModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
