import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { DnsnewsComponent } from './pages/dnsnews/dnsnews.component';
import { CertificationComponent } from './pages/certification/certification.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { BikesComponent } from './pages/bikes/bikes.component';
import { IndexComponent } from './index/index.component';



const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path:  '',
    component:  IndexComponent,
    children: [
    
  { path: 'home', component: HomeComponent },
  { path: 'aboutus', component: AboutComponent },
  { path: 'dnsnews', component: DnsnewsComponent },
  { path: 'certification', component: CertificationComponent },
  { path: 'contactus', component: ContactusComponent },
    ]
    },

  { path: 'bikes', component: BikesComponent },
  { path: 'bikes/:id', component: BikesComponent },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
